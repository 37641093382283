import styled from 'styled-components';
import { MapContainer } from 'react-leaflet';
import ButtonIcon from '../../../../../components/button/ButtonIcon';
import { Flex } from '../../../../../assets/style';

export const OrderViewDriverMapContainer = styled(Flex)`
    flex: 2;
    width: 100%;  
`;


export const OrderMapContainer = styled(MapContainer)`
    &&& {
        width: 100% !important;
        height: 100% !important;
    }
`;

export const OrderViewMapOpenInWindowButton = styled(ButtonIcon)`
    &&& {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
        background: black;
        color: white;
        border-radius: 0;
        border-bottom-left-radius: 10px !important;
    }
`;
