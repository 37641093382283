import React, { useCallback, useEffect, useState } from 'react';
import { OrderMapContainer, OrderViewDriverMapContainer, OrderViewMapOpenInWindowButton } from './style';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { selectIsMapOpen, setOpenMap } from '../../../../../store/app/appSlice';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import OrderMap from '../../../../OrderMap';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../store/hooks';

const OrderViewMap = () => {
    const isMapOpen = useSelector(selectIsMapOpen);
    const [compLoading, setCompLoading] = useState(true);
    const dispatch = useAppDispatch();

    const openMap = useCallback(() => {
        localStorage.setItem('mapOpen', '1');
        dispatch(setOpenMap(true));
    }, [dispatch]);

    useEffect(() => {
        setCompLoading(false);
    }, []);


    return !isMapOpen ? (
        <OrderViewDriverMapContainer>
            <OrderMapContainer>
                {!compLoading ? <OrderMap /> : <></>}
                <OrderViewMapOpenInWindowButton title={'Otvori u novom prozoru'} onClick={openMap}>
                    <OpenInNewIcon />
                </OrderViewMapOpenInWindowButton>
            </OrderMapContainer>
        </OrderViewDriverMapContainer>
    ) : null;
};


export default OrderViewMap;