/*
import React, { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import OrderMap from '../../views/OrderMap'; // Adjust path as needed

interface WindowProps {
    children?: ReactNode;
}

const Window: React.FC<WindowProps> = ({ children }) => {
    const [win, setWin] = useState<Window | null>(null);
    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        // Attempt to open the new window
        const newWindow = window.open('', '', `width=${screen.width},height=${screen.height},scrollbars=no,resizable=yes`);
        if (!newWindow) {
            console.error('Failed to open new window. It may be blocked by a popup blocker.');
            return;
        }

        // Set the title and create the container for the portal
        newWindow.document.title = 'Ding Dong';
        const newEl = newWindow.document.createElement('div');
        newEl.id = 'window-container';
        newEl.style.height = '100%'; // Set height to full viewport height
        newEl.style.width = '100%'; // Set width to full viewport width
        newEl.style.position = 'relative';
        newWindow.document.body.appendChild(newEl);

        const link = newWindow.document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = 'https://unpkg.com/leaflet/dist/leaflet.css';
        newWindow.document.head.appendChild(link);

        // Inject the stylesheet into the new window (if needed)
        const link1 = newWindow.document.createElement('link');
        link1.rel = 'stylesheet';
        link1.type = 'text/css';
        link1.href = '/css/map.css'; // Adjust the path as needed
        newWindow.document.head.appendChild(link1);

        // Wait for the window to fully load before rendering content
        setWin(newWindow); // Set the window
        setContainer(newEl); // Set the container for the portal

        // Clean up: close the window when the component unmounts
        return () => {
            if (newWindow) {
                console.log('Closing new window...');
                newWindow.close();
            }
        };
    }, []);

    // Debugging logs for state
    console.log('Window state:', win);
    console.log('Container state:', container);

    // Render the content into the new window's container once the window and container are ready
    if (win && container) {
        return ReactDOM.createPortal(children || <OrderMap />, container);
    }

    return null; // Render nothing until the new window and container are ready
};

export default Window;
*/


import React, { useEffect, useState, ReactNode, useRef } from 'react';
import ReactDOM from 'react-dom';
import OrderMap from '../../views/OrderMap'; // Adjust path as needed

interface WindowProps {
    children?: ReactNode;
    onClose?: () => void;
}

const Window: React.FC<WindowProps> = ({ children, onClose }) => {
    const [win, setWin] = useState<Window | null>(null);
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const style = useRef({} as any);
    const [mapLoaded, setMapLoaded] = useState<boolean>(false); // Track if the map is loaded


    useEffect(() => {
        // Attempt to open the new window
        const newWindow = window.open('', '', `width=${screen.width},height=${screen.height},fullscreen=yes,scrollbars=no,resizable=no`);
        if (!newWindow) {
            console.error('Failed to open new window. It may be blocked by a popup blocker.');
            return;
        }

        // Set the title and create the container for the portal
        newWindow.document.title = 'Ding Dong';
        const newEl = newWindow.document.createElement('div');
        newEl.id = 'window-container';
        newEl.style.height = '100%';
        newEl.style.width = '100%';
        newEl.style.position = 'relative';
        newWindow.document.body.appendChild(newEl);

        // Inject the stylesheet into the new window (if needed)
        const link1 = newWindow.document.createElement('link');
        link1.rel = 'stylesheet';
        link1.type = 'text/css';
        link1.href = '/css/map.css'; // Adjust the path as needed
        newWindow.document.head.appendChild(link1);

        newWindow.focus();


        // Set state immediately after creating the container
        setContainer(newEl);
        setWin(newWindow);

        // Update styles based on the new window size
        if (newWindow.innerWidth) {
            style.current = {
                width: `${newWindow.innerWidth}px`,
                height: `${newWindow.innerHeight}px`
            };
        }

        const handleWindowClose = () => {
            if (onClose) onClose();
            newWindow.close();
        };

        // Handle window load event
        newWindow.addEventListener('load', () => {
            console.log('New window loaded successfully.');
            setMapLoaded(true); // Set to true once map is ready (You can also use a callback)
        });

        // Handle resizing
        newWindow.addEventListener('resize', () => {
            style.current = {
                width: `${newWindow.innerWidth}px`,
                height: `${newWindow.innerHeight}px`
            };
        });

        newWindow.addEventListener('beforeunload', handleWindowClose);

        // Clean up: close the window when the component unmounts
        return () => {
            if (newWindow) {
                console.log('Closing new window...');
                newWindow.removeEventListener('beforeunload', handleWindowClose);
                newWindow.close();
            }
        };
    }, []);

    useEffect(() => {
        if (!win) return;
        if (!mapLoaded) return;

        function beforeUnload(e: BeforeUnloadEvent) {
            e.preventDefault();
            console.log('called', e);
        }

        win.addEventListener('beforeunload', beforeUnload);

        return () => {
            win.removeEventListener('beforeunload', beforeUnload);
        };
    }, [mapLoaded, win]);


    if (win && container) {
        return ReactDOM.createPortal(mapLoaded ? children :
            <OrderMap style={style.current} onLoad={() => setMapLoaded(true)} />, container);
    }

    return null; // Render nothing until the new window and container are ready
};

export default Window;
