

export const SHIFT_SCHEDULING_TABLE_EVENTS = {
    ADD_NEW_SHIFT_ITEM: 'SHIFT_SCHEDULING_TABLE_EVENTS_ADD_NEW_SHIFT_ITEM',
    EDIT_SHIFT: 'SHIFT_SCHEDULING_TABLE_EVENTS_EDIT_SHIFT',
    PREVIEW_SHIFT: 'SHIFT_SCHEDULING_TABLE_EVENTS_PREVIEW_SHIFT'
};



export const SHIFT_SCHEDULING_PREVIEW_EVENTS = {
    TEMP_STOP: 'SHIFT_SCHEDULING_PREVIEW_EVENTS_TEMP_STOP',
    TEMP_CAN_RESUME: 'SHIFT_SCHEDULING_PREVIEW_EVENTS_TEMP_CAN_RESUME',
    END_SHIFT: 'SHIFT_SCHEDULING_PREVIEW_EVENTS_END_SHIFT',
};


export enum SHIFT_SCHEDULING_STATUS {
    START = 0x1,
    END = 0x2,
    PAUSE = 0x3,
    TEMP_STOP = 0x4,
    TEMP_CAN_RESUME = 0x5,
    DISP_END=0x6,
    TEMP_NOT_WORK = 0x7
}