import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { OrderViewListContainer } from './style';
import useGetCurrentDriversQuery from '../../../../../core/api/hooks/query/orders/useGetCurrentDriversQuery';
import OrderDriverItem from './OrderDriverItem';
import UiDataContext from '../../../../../context/UiContext/context';
import OrderDriverViewContext from './context/context';
import { deepEqual } from 'fast-equals';
import EmptyList from '../OrdersViewPart/EmptyList';
import { TUseOptimizeEventData, useOptimizeEventClick } from '../../../../../hooks/useOptimizeEventClick';
import { OrderDriverViewActionsListEvents } from '../OrdersViewPart/d';
import OrderViewAssignContext from '../context/context';
import { errorToast, successToast } from '../../../../../components/Utils/Toast';
import { queryClient } from '../../../../../core/root/ReactQueryProvider';
import useAssignOrderMutation from '../../../../../core/api/hooks/mutations/order/useAssignOrderMutation';
import { useTranslation } from 'react-i18next';
import { selectShiftDrivers } from '../../../../../store/app/appSlice';
import { useSelector } from 'react-redux';


const OrderDriversViewList = () => {

    const { t } = useTranslation();
    const { setLoading, resetLoading, needDriverRefetch, clearRefetchDrivers } = useContext(UiDataContext);
    const [listData, setListData] = useState([] as any);
    const { filter, isNeedRefetch, clearNeedRefetch, requestOptions } = useContext(OrderDriverViewContext);
    const { selectedDriver, selectedOrders, resetState } = useContext(OrderViewAssignContext);
    const shiftDrivers = useSelector(selectShiftDrivers);
    const refReqOptions = useRef({});

    const assignOrderMutation = useAssignOrderMutation();
    const isFilter = useMemo(() => filter && Object.keys(filter).filter(x => !!filter[x])?.length, [filter]);

    const refetchDone = useCallback(() => {
        clearNeedRefetch();
    }, [clearNeedRefetch]);

    const _requestOptions = useMemo(() => {
        if (!deepEqual(requestOptions, refReqOptions.current)) refReqOptions.current = requestOptions;
        return refReqOptions.current;
    }, [requestOptions, refReqOptions]);

    const {
        data: listState,
        error,
        isFetching,
        isLoading,
        isFetched,
        refetch
    } = useGetCurrentDriversQuery(_requestOptions, refetchDone);

    useEffect(() => {
        if (!isLoading || !isFetched) {
            resetLoading();
            return;
        }
        setLoading();
        return () => {
            resetLoading();
        };
    }, [setLoading, resetLoading, isLoading, isFetched]);

    useEffect(() => {
        if (!isNeedRefetch || isFetching || error) return;
        refetch();
    }, [isNeedRefetch, refetch, isFetching, error]);

    useEffect(() => {
        if (!needDriverRefetch) return;
        refetch();
        clearRefetchDrivers();
    }, [needDriverRefetch, refetch, clearRefetchDrivers]);

    useEffect(() => {
        if (!listState) return;
        setListData([...(listState || [])].map((x: any, index: number) => ({
            ...x,
            index: index + 1
        })));
    }, [listState]);

    const handleAssign = useCallback(async () => {
        if (!selectedDriver || !selectedOrders?.length) return false;
        try {
            setLoading();
            const result = await assignOrderMutation.mutateAsync({
                driverId: selectedDriver,
                shiftSchedulingId: (shiftDrivers || [])?.find((x: any) => x.driverId === selectedDriver)?.shiftSchedulingId,
                orderIds: selectedOrders
            });
            if (result?.status === 200) {
                resetState && resetState();
                successToast(t(result.message));
                await queryClient.invalidateQueries({ queryKey: ['get-orders'] });
                await queryClient.invalidateQueries({ queryKey: ['get-current-drivers'] });
            }
        } catch (e: any) {
            errorToast(t(e?.response?.data?.key || e?.response?.data?.message));
        } finally {
            resetLoading();
        }
        return true;
    }, [selectedDriver, selectedOrders, resetState, setLoading, assignOrderMutation, t, resetLoading, shiftDrivers]);


    const { onClickHandler } = useOptimizeEventClick({
        eventHandler(data: TUseOptimizeEventData) {
            if (data.action === OrderDriverViewActionsListEvents.ASSIGNED_DRIVER) {
                if (data.id) {
                    handleAssign();
                    return;
                }
            }
        }
    });


    return (
        <OrderViewListContainer onClick={onClickHandler} data-action-root>
            {listData?.length ?
                (listData || []).map((driver: any, index: number) => <OrderDriverItem driver={driver} key={index} />)
                : <EmptyList isFilter={isFilter} />
            }
        </OrderViewListContainer>
    );
};

export default OrderDriversViewList;