import React, { useEffect, useMemo, useRef } from 'react';
import { OrderViewMapContainer } from './style';
import L from 'leaflet';
import { Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import { useSelector } from 'react-redux';
import { selectIsMapOpen, selectMapState } from '../../store/app/appSlice';
import useGetCurrentDriversQuery from '../../core/api/hooks/query/orders/useGetCurrentDriversQuery';
import { pick } from 'lodash';

const CustomMarker = ({ marker }: any) => {
    const icon = useMemo(() => {
        const type = marker.vehicleType === 2 ? 'circle' : marker.vehicleType === 1 ? 'triangle' : '';
        const color = marker.isBusy ? 'red' : marker.status === 3 ? 'yellow' : 'green';
        return L.divIcon({
            className: 'custom-icon',
            html: `<div class='custom-marker-icon ${type === 'triangle' ? 'start' : ''}'>
             <div class='marker-icon ${type} ${color}'></div>
             <div class='marker-icon-label ${type}'>${marker.driverId}</div>
           </div>`,
            iconSize: [20, 20],
            popupAnchor: [0, -20]
        });
    }, [marker]);
    return (
        <Marker
            position={pick(marker, ['lat', 'lng'])}
            icon={icon}
        >
            <Popup>
                <b>{marker.driver}</b><br />
            </Popup>
        </Marker>
    );
};

const MapResize = ({ isMap, style }: any) => {
    const map = useMap();

    const handleResize = () => {

        console.log(map);

        if (style?.width && style?.height) {
            map.invalidateSize();
            map.flyTo(map.getCenter());
        }
    };


    useEffect(() => {
        if (!isMap) return;
        handleResize();
    }, [style, isMap]);

    return null;
};

const OrderMap = ({ style }: any) => {
    const isMapOpen = useSelector(selectIsMapOpen);
    const mapState = useSelector(selectMapState);
    const mapRef = useRef<any>(null);

    const markers = useMemo(() => mapState ? Object.keys(mapState).map(key => {
        return mapState[key];
    }) : [], [mapState]);

    useGetCurrentDriversQuery();

    const resizeMap = (mapRef: any) => {
        const resizeObserver = new ResizeObserver(() => mapRef.current?.invalidateSize());
        const container = document.getElementById('map-container');
        if (container) {
            resizeObserver.observe(container);
        }
    };

    if (isMapOpen && !style?.height) return null;

    return (
        <OrderViewMapContainer
            ref={mapRef}
            center={{
                lat: 43.58226,
                lng: 21.32918
            } as any}
            zoom={14}
            scrollWheelZoom={true}
            whenReady={() => resizeMap(mapRef)}
            dragging={false}
        >
            <MapResize isMap={isMapOpen} style={style} />
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {markers && markers.length ? markers.map((marker, index) => <CustomMarker key={index}
                                                                                      marker={marker} />) : null}
        </OrderViewMapContainer>
    );
};


export default OrderMap;